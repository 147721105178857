@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.socials {
  position: relative;
  z-index: 51;
  display: flex;
  height: 100%;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .socials.in-header .toggle-btn {
    display: block;
    width: 9.375vw;
    height: 9.375vw;
  }
}
@media only screen and (max-width: 767px) {
  .socials.in-header .social-button {
    margin-bottom: 1.5625vw;
    transition: all ease 0.4s;
  }
  .socials.in-header .social-button:first-child {
    transform: translateY(-15.625vw);
  }
  .socials.in-header .social-button:nth-child(1n+1) {
    transform: translateY(-37.5vw);
  }
}
.socials.in-header .overflow-wrap {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .socials.in-header .overflow-wrap {
    position: absolute;
    top: 10.9375vw;
    left: 0;
    display: flex;
    width: 9.375vw;
    flex-direction: column;
    justify-content: space-around;
    overflow: hidden;
    background-color: transparent;
    opacity: 0;
    visibility: hidden;
    transition: all ease 0.2s;
  }
}
.socials.in-header .button {
  display: flex;
  width: 2.6041666667vw;
  height: 2.6041666667vw;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 2px #000 solid;
  border-left: none;
}
@media only screen and (max-width: 767px) {
  .socials.in-header .button {
    width: 9.375vw;
    height: 9.375vw;
    border: 2px #fff solid;
    background-color: #000;
    border-radius: 50%;
  }
}
.socials.in-header .button:last-child {
  border-left: 2px solid #000;
}
@media only screen and (max-width: 767px) {
  .socials.in-header .button:last-child {
    border-left: 2px solid #fff;
  }
}
.socials.in-header .button i {
  width: 1.3541666667vw;
  height: 1.3541666667vw;
}
@media only screen and (max-width: 767px) {
  .socials.in-header .button i {
    width: 4.6875vw;
    height: 4.6875vw;
  }
}
.socials.in-header .button i svg {
  width: 100% !important;
  height: 100% !important;
}
.socials.in-header .button:hover i {
  animation: Hop 0.8s cubic-bezier(0, 0.87, 0.5, 1);
}
@media only screen and (max-width: 767px) {
  .socials.in-header .button.copy-button {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .socials.open .overflow-wrap {
    opacity: 1;
    visibility: visible;
  }
}
@media only screen and (max-width: 767px) {
  .socials.open .overflow-wrap .social-button:first-child {
    transform: translateY(0);
  }
  .socials.open .overflow-wrap .social-button:nth-child(1n+1) {
    transform: translateY(0);
  }
}
.socials .social-button svg {
  width: 100%;
  height: auto;
}
.socials .social-button img {
  width: 100%;
}
.socials .social-button:hover {
  opacity: 0.8;
}
.socials .button {
  display: flex;
  width: 2.6041666667vw;
  height: 2.6041666667vw;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 2px #000 solid;
  border-left: none;
}
@media only screen and (max-width: 767px) {
  .socials .button {
    width: 9.375vw;
    height: 9.375vw;
    border: 2px #000 solid;
    border-left: none;
  }
}
.socials .button:last-child {
  border-left: 2px solid #000;
}
@media only screen and (max-width: 767px) {
  .socials .button:last-child {
    border-left: 2px solid #000;
  }
}
.socials .button i {
  width: 1.3541666667vw;
  height: 1.3541666667vw;
}
@media only screen and (max-width: 767px) {
  .socials .button i {
    width: 4.6875vw;
    height: 4.6875vw;
  }
}
.socials .button i svg {
  width: 100% !important;
  height: 100% !important;
}
.socials .button:hover i {
  animation: Hop 0.8s cubic-bezier(0, 0.87, 0.5, 1);
}
.socials .copy-button {
  position: relative;
}
.socials .copy-button .copy-text {
  position: absolute;
  bottom: -1.3020833333vw;
  left: 50%;
  white-space: nowrap;
  font-size: 0.9375vw;
  line-height: 1.0416666667vw;
  color: #000;
  transform: translate(-50%, 0);
  opacity: 0;
}
@media only screen and (max-width: 767px) {
  .socials .copy-button .copy-text {
    bottom: -3.90625vw;
    font-size: 2.8125vw;
    line-height: 3.125vw;
  }
}
.socials .copy-button .copy-text.show {
  opacity: 1;
  animation: Shiver 0.5s ease-in-out forwards;
}
.socials .overflow-wrap {
  display: flex;
}
.socials .toggle-btn {
  display: none;
}
.socials .toggle-btn i {
  width: 100%;
  height: 100%;
  border: solid 2px #fff;
  border-radius: 50%;
}
.socials .toggle-btn i svg {
  width: 100% !important;
  height: 100% !important;
}

@keyframes Hop {
  25% {
    transform: translateY(-0.3645833333vw);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-0.2604166667vw);
  }
  90% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-0.1041666667vw);
  }
}
@keyframes Shiver {
  0% {
    transform: translate(-50%, 0) rotate(0deg);
  }
  25% {
    transform: translate(-50%, 0) rotate(10deg);
  }
  50% {
    transform: translate(-50%, 0) rotate(-5deg);
  }
  75% {
    transform: translate(-50%, 0) rotate(5deg);
  }
  100% {
    transform: translate(-50%, 0) rotate(0deg);
  }
}