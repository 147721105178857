@charset "UTF-8";
/* stylelint-disable no-descending-specificity */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
@value transitionIn: 500ms;
@value transitionOut: 500ms;
.intro {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  width: 100%;
  height: auto;
  min-height: 100vh;
  margin: 0 auto;
  background-color: #e9ecf5;
}
.intro.appear, .intro.enter {
  opacity: 0;
}
.intro.appearing, .intro.entering {
  opacity: 1;
  transition: opacity;
  transition-duration: transitionIn;
}
.intro.exit {
  opacity: 1;
}
.intro.exiting {
  opacity: 0;
  transition: opacity;
  transition-duration: transitionOut;
}
.intro .my-masonry-grid {
  display: flex;
  width: auto;
  min-width: 100%;
  padding: 7.8125vw 8.3333333333vw 2.6041666667vw 3.125vw;
  margin-left: -2.0833333333vw;
}
@media only screen and (max-width: 767px) {
  .intro .my-masonry-grid {
    padding: 20.3125vw 3.125vw 7.8125vw 3.125vw;
    margin-left: 0;
  }
}
.intro .my-masonry-grid-column {
  padding-left: 2.0833333333vw;
}
@media only screen and (max-width: 767px) {
  .intro .my-masonry-grid-column {
    padding-left: 0;
  }
}