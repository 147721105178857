@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.button {
  position: relative;
  display: inline-block;
  width: 5.46875vw;
  height: 2.4479166667vw;
  text-decoration: none;
  color: currentColor;
  opacity: 1;
  transition: all 0.35s ease-in-out;
}
@media only screen and (max-width: 767px) {
  .button {
    width: 23.4375vw;
    height: 9.375vw;
  }
}
@media only screen and (min-width: 1025px) {
  .button:hover::after {
    opacity: 1;
    visibility: visible;
  }
}
.button:hover {
  cursor: pointer;
  opacity: 0.9;
}
.button.small {
  height: 1.875vw;
  padding: 10px 0;
}
.button.small .label {
  font-size: 1.1979166667vw;
  line-height: 1;
}
@media only screen and (max-width: 767px) {
  .button.small .label {
    font-size: 4.6875vw;
  }
}
.button.disabled {
  pointer-events: none;
  background-color: #b0c4cb !important;
}
.button.withIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button.withIcon.left .icon {
  margin: 1px -0.2083333333vw 0 0;
  transform: rotate(90deg);
}
.button.withIcon.right {
  flex-direction: row-reverse;
}
.button.withIcon.right .icon {
  margin: 0.1041666667vw 0 0 -0.2083333333vw;
  transform: rotate(-90deg);
}
.button.withIcon .icon {
  width: 0.9375vw;
  height: 0.9375vw;
}
.button.withIcon .icon svg {
  width: 100%;
  height: 100%;
}
.button .label {
  position: relative;
  font-family: "NarkissBlock Semibold", sans-serif;
  font-size: 1.25vw;
  line-height: 1.5;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .button .label {
    font-size: 4.6875vw;
  }
}

.is-mobile {
  width: 23.4375vw;
  height: 9.375vw;
}
.is-mobile.withIcon .icon {
  width: 2.8125vw;
  height: 2.8125vw;
}
.is-mobile.small {
  width: 20.9375vw;
  height: 7.1875vw;
  padding: 5px 0 7px;
}
.is-mobile.small .label {
  font-size: 4.6875vw;
}
.is-mobile .label {
  font-size: 4.6875vw;
}