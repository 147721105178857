@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.header {
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 5.7291666667vw;
  box-shadow: 0 6px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #e9ecf5;
}
@media only screen and (max-width: 767px) {
  .header {
    height: 15.625vw;
    box-shadow: none;
    background-color: rgb(17, 14, 14);
  }
}
.header.without-shadow {
  box-shadow: none;
  background-color: transparent;
}
@media only screen and (max-width: 767px) {
  .header.without-shadow {
    background-color: rgb(17, 14, 14);
  }
}
.header-inner {
  display: flex;
  height: 100%;
  padding: 0 8.3333333333vw 0 3.125vw;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .header-inner {
    width: 100%;
    padding: 0 3.125vw;
  }
}
.header-inner .right-part {
  display: flex;
  height: 100%;
  align-items: center;
}
.header-inner .right-part .ynet-logo {
  display: none;
}
@media only screen and (max-width: 767px) {
  .header-inner .right-part .ynet-logo {
    display: block;
    width: 9.375vw;
    flex-shrink: 0;
  }
}
.header-inner .right-part .super-brand-logo {
  width: 11.4583333333vw;
  margin-right: 1.25vw;
  transition: all ease 0.3s;
}
@media only screen and (max-width: 767px) {
  .header-inner .right-part .super-brand-logo {
    display: none;
  }
}
.header-inner .right-part .super-brand-logo:hover {
  transform: translateY(-2px);
}
.header-inner .right-part .super-brand-logo-black {
  width: 11.4583333333vw;
  margin-right: 1.25vw;
  transition: all ease 0.3s;
}
@media only screen and (max-width: 767px) {
  .header-inner .right-part .super-brand-logo-black {
    display: none;
  }
}
.header-inner .right-part .super-brand-logo-black:hover {
  transform: translateY(-2px);
}
.header-inner .right-part .super-brand-logo-mobile {
  display: none;
}
@media only screen and (max-width: 767px) {
  .header-inner .right-part .super-brand-logo-mobile {
    display: block;
    width: 20.46875vw;
    margin-right: 2.5vw;
  }
}
.header-inner .right-part .second-logo-desktop {
  width: 18.4375vw;
  transition: all ease 0.3s;
}
@media only screen and (max-width: 767px) {
  .header-inner .right-part .second-logo-desktop {
    display: none;
  }
}
.header-inner .right-part .second-logo-desktop:hover {
  transform: translateY(-2px);
}
.header-inner .right-part .second-logo-mobile {
  display: none;
}
@media only screen and (max-width: 767px) {
  .header-inner .right-part .second-logo-mobile {
    display: block;
    width: 34.21875vw;
    margin-right: 3.28125vw;
    flex-shrink: 0;
  }
}
.header-inner .right-part img {
  display: block;
  width: 100%;
}
.header-inner .left-part {
  display: flex;
}
.header-inner .left-part .accessability {
  display: none;
}
@media only screen and (max-width: 767px) {
  .header-inner .left-part .accessability {
    display: block;
    width: 9.375vw;
    height: 9.375vw;
  }
}
.header-inner .left-part.is-hide-socialls {
  display: none;
}
@media only screen and (max-width: 767px) {
  .header-inner .left-part.is-hide-socialls {
    display: flex;
  }
}