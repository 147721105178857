@charset "UTF-8";
/* stylelint-disable no-descending-specificity */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.innerPage {
  height: auto;
  min-height: 100vh;
  padding-top: 6.25vw;
  padding-right: 8.3333333333vw;
  padding-left: 3.125vw;
  color: #fff;
  transition: 1s;
}
@media only screen and (max-width: 767px) {
  .innerPage {
    position: relative;
    padding-top: 20.3125vw;
    padding-right: 4.6875vw;
    padding-bottom: 20.3125vw;
    padding-left: 4.6875vw;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 767px) {
  .wrapper {
    flex-direction: column-reverse;
  }
}

.leftSection {
  position: relative;
}
.leftSection .imgText {
  position: absolute;
  top: 1.0416666667vw;
  right: 100%;
  font-size: 0.9375vw;
  line-height: 1.7708333333vw;
  font-family: "Open Sans Hebrew", sans-serif;
  transform: rotate(-90deg);
  transform-origin: top right;
  text-shadow: 0 3px 6px #000;
  white-space: nowrap;
}
@media only screen and (max-width: 767px) {
  .leftSection .imgText {
    font-size: 2.8125vw;
    line-height: 5.3125vw;
  }
}

.rightSection {
  position: relative;
  width: 40.1041666667vw;
  padding-top: 1.25vw;
}
@media only screen and (max-width: 767px) {
  .rightSection {
    width: 100%;
    padding-top: 3.125vw;
  }
}
.rightSection .topMiniTitle {
  position: absolute;
  top: 2.0833333333vw;
  right: 0;
  font-size: 1.25vw;
  letter-spacing: -0.48px;
  color: #000;
}
@media only screen and (max-width: 767px) {
  .rightSection .topMiniTitle {
    position: relative;
    top: 0;
    margin-bottom: 3.75vw;
    font-size: 3.75vw;
  }
}
.rightSection .rightWrapper {
  display: flex;
  height: 100%;
  margin-right: 5.7291666667vw;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.5s;
}
@media only screen and (max-width: 767px) {
  .rightSection .rightWrapper {
    margin-right: 0;
  }
}
.rightSection .rightWrapper .bottomSection .bottomBlock {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
@media only screen and (max-width: 767px) {
  .rightSection .rightWrapper .bottomSection .bottomBlock {
    padding-top: 3.125vw;
    border-top: 2px solid #000;
  }
}
@media only screen and (max-width: 767px) {
  .rightSection .rightWrapper .bottomSection .bottomBlock.marginForBottomBlock {
    margin-top: 8.59375vw;
  }
}
.rightSection .rightWrapper .bottomSection .bottomBlock .bottom-block-right-part {
  display: flex;
  align-items: center;
}
.rightSection .rightWrapper .bottomSection .bottomBlock .bottom-block-right-part .name {
  padding-right: 0.8333333333vw;
  font-size: 0.9375vw;
  line-height: 1.7708333333vw;
  color: #000;
  font-family: "Open Sans Hebrew", sans-serif;
}
@media only screen and (max-width: 767px) {
  .rightSection .rightWrapper .bottomSection .bottomBlock .bottom-block-right-part .name {
    padding-right: 3.125vw;
    font-size: 2.8125vw;
    line-height: 5.3125vw;
  }
}
.rightSection .rightWrapper .bottomSection .likeBlock {
  display: flex;
  width: 5.8333333333vw;
  height: 2.6041666667vw;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #000;
}
@media only screen and (max-width: 767px) {
  .rightSection .rightWrapper .bottomSection .likeBlock {
    width: 21.09375vw;
    height: 9.375vw;
  }
}
.rightSection .rightWrapper .bottomSection .likeBlock .loveButton {
  display: flex;
  width: 2.6041666667vw;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-right: 2px solid #000;
}
@media only screen and (max-width: 767px) {
  .rightSection .rightWrapper .bottomSection .likeBlock .loveButton {
    width: 9.375vw;
  }
}
.rightSection .rightWrapper .bottomSection .likeBlock .loveIcon {
  width: 1.1458333333vw;
  height: 1.0416666667vw;
  animation: love 1.8s ease-in-out;
  fill: #ed1c24;
}
@media only screen and (max-width: 767px) {
  .rightSection .rightWrapper .bottomSection .likeBlock .loveIcon {
    width: 3.4375vw;
    height: 3.125vw;
  }
}
.rightSection .rightWrapper .bottomSection .likeBlock .loveIcon.loveIconDisable {
  fill: #000;
  animation: none;
}
.rightSection .rightWrapper .bottomSection .likeBlock .countLikes {
  display: flex;
  width: 3.2291666667vw;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .rightSection .rightWrapper .bottomSection .likeBlock .countLikes {
    width: 11.71875vw;
  }
}
.rightSection .rightWrapper .bottomSection .likeBlock p {
  font-size: 0.9375vw;
  color: #000;
  font-family: "Open Sans Hebrew", sans-serif;
}
@media only screen and (max-width: 767px) {
  .rightSection .rightWrapper .bottomSection .likeBlock p {
    font-size: 3.125vw;
  }
}
.rightSection .rightWrapper .bottomSection .backButton {
  display: flex;
  width: 14.3229166667vw;
  height: 4.1666666667vw;
  padding: 0 1.5625vw;
  justify-content: center;
  align-items: center;
  background-color: #000;
}
.rightSection .rightWrapper .bottomSection .backButton span {
  margin-left: 0.9739583333vw;
  font-size: 1.7708333333vw;
  letter-spacing: -0.68px;
}
.rightSection .rightWrapper .bottomSection .backButton .arrowLeft {
  width: 1.8229166667vw;
  transition: all ease 0.3s;
}
.rightSection .rightWrapper .bottomSection .backButton:hover .arrowLeft {
  transform: translateX(-2px);
}
.rightSection .rightInnerBlock {
  display: flex;
  flex-direction: row-reverse;
}
@media only screen and (max-width: 767px) {
  .rightSection .rightInnerBlock {
    margin-bottom: 3.125vw;
  }
}
.rightSection .rightInnerBlock .innerTextBlock {
  display: flex;
  flex-direction: column;
}
.rightSection .firstTitle {
  width: 40.1041666667vw;
  font-size: 5.2083333333vw;
  line-height: 5.2083333333vw;
  letter-spacing: -2px;
  font-weight: 400;
  transition: opacity 0.7s;
}
@media only screen and (max-width: 767px) {
  .rightSection .firstTitle {
    width: 100%;
    font-size: 10.3125vw;
    letter-spacing: -1.32px;
    line-height: 10.3125vw;
  }
}
.rightSection .title {
  width: 34.375vw;
  font-size: 4.1666666667vw;
  line-height: 4.1666666667vw;
  letter-spacing: -1.6px;
  font-weight: 400;
  transition: opacity 0.7s;
}
@media only screen and (max-width: 767px) {
  .rightSection .title {
    width: 75vw;
    font-size: 7.1875vw;
    letter-spacing: -0.92px;
    line-height: 7.1875vw;
  }
}
.rightSection h3 {
  position: relative;
  top: 0.78125vw;
  width: 31.25vw;
  font-size: 1.25vw;
  line-height: 1.875vw;
  font-family: "Open Sans Hebrew", sans-serif;
  font-weight: 400;
  transition: 1s;
}
@media only screen and (max-width: 767px) {
  .rightSection h3 {
    width: 75vw;
    font-size: 3.75vw;
    line-height: 5.625vw;
  }
}
.rightSection .numberSection {
  position: absolute;
  right: 0vw;
  display: flex;
  width: 4.1666666667vw;
  height: 4.1666666667vw;
  padding: 0.5208333333vw 1.0416666667vw;
  margin-top: 0.9375vw;
  justify-content: center;
  align-items: center;
  font-size: 2.0833333333vw;
  border: 0.4166666667vw solid #000;
  color: #000;
  opacity: 0;
  transition: 0.5s;
}
@media only screen and (max-width: 767px) {
  .rightSection .numberSection {
    width: 12.5vw;
    height: 12.5vw;
    font-size: 6.25vw;
    border: 1.25vw solid #000;
  }
}
.rightSection .isRightWrapperWelcomeActive {
  margin-right: 0vw;
}
.rightSection .isRightWrapperWelcomeActive .rightInnerBlock {
  flex-direction: row;
}

.coverImage {
  display: block;
  width: 43.75vw;
  height: 39.0625vw;
  object-fit: cover;
}
@media only screen and (max-width: 767px) {
  .coverImage {
    width: 100%;
    height: 68.75vw;
  }
}
@media only screen and (min-width: 1024px) and (max-height: 900px) {
  .coverImage {
    height: 35.4166666667vw;
  }
}
@media only screen and (min-width: 1024px) and (max-height: 850px) {
  .coverImage {
    height: 32.8125vw;
  }
}

.welcomeButtonWrapper {
  padding-bottom: 1.7708333333vw;
  margin-bottom: 1.0416666667vw;
  border-bottom: 2px solid #000;
}
@media only screen and (max-width: 767px) {
  .welcomeButtonWrapper {
    padding-bottom: 11.71875vw;
    margin-bottom: 3.125vw;
    border-bottom: none;
  }
}
.welcomeButtonWrapper .welcomeButton {
  display: flex;
  width: 12.2395833333vw;
  height: 4.1666666667vw;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  background-color: #000;
  font-size: 2.0833333333vw;
}
@media only screen and (max-width: 767px) {
  .welcomeButtonWrapper .welcomeButton {
    width: 36.71875vw;
    height: 12.5vw;
    margin-top: 3.90625vw;
    font-size: 6.09375vw;
    letter-spacing: -0.78px;
  }
}
.welcomeButtonWrapper .welcomeButton:hover .arrowLeft {
  transform: translateX(-2px);
}
.welcomeButtonWrapper .welcomeButton .arrowLeft {
  width: 2.0833333333vw;
  margin-right: 0.2604166667vw;
  transition: all ease 0.3s;
}
@media only screen and (max-width: 767px) {
  .welcomeButtonWrapper .welcomeButton .arrowLeft {
    width: 6.25vw;
    height: 6.25vw;
    margin-right: 1.09375vw;
    fill: #fff;
  }
}
.welcomeButtonWrapper .welcomeButton .welcomeButtonArrow {
  width: 1.25vw;
  margin-right: 1.0416666667vw;
}
@media only screen and (max-width: 767px) {
  .welcomeButtonWrapper .welcomeButton .welcomeButtonArrow {
    width: 3.75vw;
    height: 3.75vw;
    margin-right: 3.125vw;
  }
}

.active {
  opacity: 1 !important;
}

.buttons {
  z-index: 10;
  display: flex;
  width: 100%;
  padding-bottom: 1.0416666667vw;
  margin-bottom: 1.0416666667vw;
  justify-content: space-between;
  border-bottom: 2px solid #000;
}
@media only screen and (max-width: 767px) {
  .buttons {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 1.5625vw;
    padding-bottom: 1.5625vw;
    border-bottom: none;
  }
}
.buttons img {
  width: 1.25vw;
}

.arrowButton {
  width: 4.1666666667vw;
  height: 4.1666666667vw;
  background-color: #000;
}
@media only screen and (max-width: 767px) {
  .arrowButton {
    width: 10.9375vw;
    height: 10.9375vw;
    background-color: #fff;
  }
}
@media only screen and (max-width: 767px) {
  .arrowButton img {
    width: 3.75vw;
    height: 5.46875vw;
  }
}
.arrowButton:hover .arrowRight {
  transform: translate(2px, 2px) rotate(-90deg);
}
.arrowButton:hover .arrowLeft {
  transform: translateX(-2px);
}

.arrowRight {
  width: 4.1666666667vw;
  height: 1.8229166667vw;
  fill: #fff;
  transform: rotate(-90deg);
  transition: all ease 0.3s;
}
@media only screen and (max-width: 767px) {
  .arrowRight {
    width: 10.9375vw;
    height: 6.25vw;
    fill: #000;
  }
}

.arrowLeft {
  width: 4.1666666667vw;
  height: 1.8229166667vw;
  fill: #fff;
  transition: all ease 0.3s;
}
@media only screen and (max-width: 767px) {
  .arrowLeft {
    width: 10.9375vw;
    height: 6.25vw;
    fill: #000;
  }
}

@keyframes love {
  0% {
    transform: scale(0.7);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.8);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}